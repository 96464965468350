<template>
  <form :action="apiUrl" method="post">
    <div v-if="debug">
      apiUrl: {{apiUrl}}
    </div>

    <input
      :type="inputType"
      v-for="(value, key) in params"
      :key="key"
      :name="key"
      :value="value"
    >
  </form>
</template>

<script>
import { apiEndPoint } from 'config/env.json'
export default {
  props: {
    csrfToken: {
      type: String,
      default: null,
    },
    debug: {
      type: Boolean,
      default: false,
    },
    params: {
      type: Object,
      default: () => {},
    },
    route: {
      type: String,
      default: null,
    },
    url: {
      type: String,
      default: null,
    },
    immediate: {
      type: Boolean,
      default: false,
    },
  },
  mounted() {
    this.init()
  },
  methods: {
    async init() {
      await this.$nextTick()
      if(this.immediate === true) {
        this.submit()
      }
    },
    submit() {
      if(this.debug === true) return
      this.$nextTick(() => {
        $(this.$el).submit()
      })
    },
  },
  computed: {
    inputType() {
      if(this.debug === true) return 'text'
      return 'hidden'
    },
    apiUrl() {
      if(this.url) return this.url
      return `${apiEndPoint}${this.route}`
    }
  },
}
</script>

<style lang="sass" type="text/sass" scoped></style>